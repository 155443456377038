import React from "react"
import PropTypes from "prop-types"

import "./i18n"

import LoadingProvider from "./../components/context/LoadingContext"
import { PostsContextProvider } from "./../components/context/PostsContext"


import "../styles/animate.min.css"
import "../styles/index.css"
import "../styles/styles.css"
import "../styles/App.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


const Layout2 = ({ children }) => {



  return (
    <LoadingProvider>
      <PostsContextProvider>
    
        <main className="App">{children}</main>

      </PostsContextProvider>
    </LoadingProvider>
  )
}

Layout2.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout2
