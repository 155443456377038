import React, { useContext } from "react"
import i18n from "i18next";

import facebookF from "../../images/footer/facebookFooter.png";
import xF from "../../images/footer/twitterX2023Logo.png";
import linkedinF from "../../images/footer/linkedinFooter.png";
import redSocial from "../../images/footer/IGIcon.png";
import logoXExsis from "../../images/homeLanding/mainPage/logoXMainPage.png";
import whatsappIcon from "../../images/footer/whatsappFooter.png";

import { withNamespaces } from "react-i18next";
import { Link } from "gatsby";
import { LoadingContext } from "../context/LoadingContext";

const BottonTextMainPage = () => {
    const { windowWidth } = useContext(LoadingContext);

  return (
    <>
    {windowWidth > 1025 ?
        <div className="text-center bottomTextCont">
            <div className="ml-2">
                <img className="mr-4 imgBtmMainPage" src={logoXExsis} alt="logo exsis" />
            </div>
            <div>
                <p className="txtBtmMainPage rights">{i18n.t("homeLanding.rights")}</p>
            </div>

            <div className={windowWidth>=1700 ? "flex mt-2 mr-64" : "flex mt-2 mr-32"}>
              <a
                      href="https://www.facebook.com/Exsisdigitalangels/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
              </a>
              <a
                      href="https://twitter.com/Exsisdigital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.linkedin.com/company/exsis-digital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
              </a>
              <a
                      href="https://www.instagram.com/exsisdigital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer logoIG" src={redSocial} alt="logo exsis" />
              </a>
              <a
                      href="https://wa.me/+573176448607"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer logoIG" src={whatsappIcon} alt="logo exsis" />
              </a>
            </div>

            <div className={windowWidth>=1700 ? "ml-80" : "ml-40"}>
                <p className={windowWidth>=1700 ? "txtBtmMainPage2Mobile" :"txtBtmMainPage2"}>{i18n.t("homeLanding.tel")}</p>
            </div>

            <div className={windowWidth>=1700 ? "ml-20" : "ml-10"}>
                <p className={windowWidth>=1700 ? "txtBtmMainPage2Mobile" :"txtBtmMainPage2"}>{i18n.t("homeLanding.email")}</p>
            </div>

            <div className={windowWidth>=1700 ? "ml-20" : "ml-10"}>
                <p className={windowWidth>=1700 ? "txtBtmMainPage2Mobile" :"txtBtmMainPage2"}>{i18n.t("homeLanding.inttel")}</p>
            </div>
            
        </div>

    :

        <div className="text-center bottomTextCont">
            <div className="ml-2">
                <img className="mr-4 imgBtmMainPage" src={logoXExsis} alt="logo exsis" />
            </div>
            <div>
                <p className="txtBtmMainPageMobile rightsMobile">{i18n.t("homeLanding.rights")}</p>
            </div>

            <div className="flex mt-2 mr-2">
            <a
                    href="https://www.facebook.com/Exsisdigitalangels/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
            <img className="mr-4 cursor-pointer" src={facebookF} alt="logo exsis" />
            </a>
            <a
                    href="https://twitter.com/Exsisdigital"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
            <img className="mr-4 cursor-pointer" src={xF} alt="logo exsis" />
            </a>
            <a
                    href="https://www.linkedin.com/company/exsis-digital"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
            <img className="mr-4 cursor-pointer" src={linkedinF} alt="logo exsis" />
            </a>
            <a
                    href="https://www.instagram.com/exsisdigital/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
            <img className="mr-4 cursor-pointer logoIG" src={redSocial} alt="logo exsis" />
            </a>
            <a
                      href="https://wa.me/+573176448607"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
              <img className="mr-4 cursor-pointer logoIG" src={whatsappIcon} alt="logo exsis" />
              </a>
            </div>
        </div>

    }

    </>
    
  )
}

export default withNamespaces()(BottonTextMainPage)
