import React, { useContext } from "react"
import i18n from "i18next";

import logoExsisMx from "../../images/homeLanding/mainPage/logoMainPage.png";

import { withNamespaces } from "react-i18next";
import { Link } from "gatsby";
import { LoadingContext } from "../context/LoadingContext";

const DescriptionLMainPage = () => {
    const { windowWidth } = useContext(LoadingContext);

  return (
    <>
        {windowWidth >1025 ? 

            <div className={windowWidth>=1700 ? "text-center contDescMainPageBig" :"text-center contDescMainPage"}>
                <Link to="/oldindex">
                <img className={windowWidth>=1700 ? "imageMainPageBig" : "imageMainPage"} src={logoExsisMx} alt="Logo" />
                </Link>
            <p className={windowWidth>=1700 ? "textMainPageBig" :"textMainPage"}>{i18n.t("homeLanding.mainpage.p1")}</p>
              <br/>
            <p className={windowWidth>=1700 ? "textMainPageBig mb-32 mt-10" : "textMainPage mb-16"}>{i18n.t("homeLanding.mainpage.p2")}</p>
      
             <div className="flex text-center">
                  {i18n.language == "en" ?
                  <>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentBig" :"subtitleTextTalent"}>E</p>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentXBig" :"subtitleTextTalentX"}>x</p>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentBig" :"subtitleTextTalent"}>plore your next one:</p>
                  </>
                  :
                  <>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentBig" :"subtitleTextTalent"}>E</p>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentXBig" :"subtitleTextTalentX"}>x</p>
                    <p className={windowWidth>=1700 ? "subtitleTextTalentBig" :"subtitleTextTalent"}>plora tu próxima:</p>
                  </>    
                  }
                    
              </div> 
      
              <a href="https://exsis.co/service-expansion">
              <button className={windowWidth >= 1700 ? 'buttonMainPageResponsive mt-10 mb-10' : 'buttonMainPageWork mt-6 mb-6'} style={{alignSelf:'center'}}>
                  <p className={windowWidth>=1700 ? 'textButtomMainPageBig' : 'textButtomMainPage'}>{i18n.t("homeLanding.mainpage.b1")}</p>
              </button>
              </a>
                  <br/>
              <a href="https://exsis.co/exceptional-careers">
              <button className={windowWidth >= 1700 ? 'buttonMainPageResponsive' : 'buttonMainPageWork '} style={{alignSelf:'center'}}>
                  <p className={windowWidth>=1700 ? 'textButtomMainPageBig' : 'textButtomMainPage'}>{i18n.t("homeLanding.mainpage.b2")}</p>
              </button>
              </a>
      
          </div>
        : 
            <div className="text-center contDescMainPageMobile">
                <Link to="/oldindex">
            <img className="imageMainPage" src={logoExsisMx} alt="Logo" />
                </Link>
            <p className="textMainPage">{i18n.t("homeLanding.mainpage.p1")}</p>
              <br/>
            <p className="textMainPage mb-10">{i18n.t("homeLanding.mainpage.p2")}</p>
      
             <div className="flex text-center ml-20">
                  {i18n.language == "en" ?
                  <>
                    <p className="subtitleTextTalent">E</p>
                    <p className="subtitleTextTalentX">x</p>
                    <p className="subtitleTextTalent">plore your next one:</p>
                  </>
                  :
                  <>
                    <p className="subtitleTextTalent">E</p>
                    <p className="subtitleTextTalentX">x</p>
                    <p className="subtitleTextTalent">plora tu próxima:</p>
                  </>    
                  }
                    
              </div> 
      
              <a href="https://exsis.co/service-expansion">
              <button className={windowWidth >= 1700 ? 'buttonMainPageResponsive mt-8 mb-8' : 'buttonMainPageWork mt-6 mb-6'} style={{alignSelf:'center'}}>
                  <p className={windowWidth>=1700 ? 'textButtomMainPageBig' : 'textButtomMainPage'}>{i18n.t("homeLanding.mainpage.b1")}</p>
              </button>
              </a>
                  <br/>
              <a href="https://exsis.co/exceptional-carrers">
              <button className={windowWidth >= 1700 ? 'buttonMainPageResponsive' : 'buttonMainPageWork '} style={{alignSelf:'center'}}>
                  <p className={windowWidth>=1700 ? 'textButtomMainPageBig' : 'textButtomMainPage'}>{i18n.t("homeLanding.mainpage.b2")}</p>
              </button>
              </a>
      
          </div>

        }
    
    </>
    
  )
}

export default withNamespaces()(DescriptionLMainPage)
