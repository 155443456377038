import React, { useContext, useEffect, useState } from "react"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next";
import DescriptionLMainPage from "../components/homeLanding/DescriptionLMainPage";
import bgMainPage from "../images/homeLanding/mainPage/fondoLandingHome.png";
import LoadingProvider, { LoadingContext } from "../components/context/LoadingContext";
import BottomTextMainPage from "../components/homeLanding/BottomTextMainPage";
import { PostsContextProvider } from "../components/context/PostsContext";

import "../styles/animate.min.css"
import "../styles/index.css"
import "../styles/styles.css"
import "../styles/App.css"
import Layout2 from "../components/layput2";

const HomeLandingPageMx = () => {
  const { windowWidth } = useContext(LoadingContext);
  const [backgroundPosition, setBackgroundPosition] = useState(
    windowWidth > 1025 ? "center center" : "center left"
  );

  useEffect(() => {
    // Actualizar la posición de fondo cuando cambie el tamaño de la ventana
    const handleWindowResize = () => {
      setBackgroundPosition(
        window.innerWidth > 1025 ? "center center" : "center left"
      );
    };

    window.addEventListener("resize", handleWindowResize);

    // Limpiar el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // Se ejecuta solo una vez después del montaje

  const backgroundStyle = {
    backgroundImage: `url(${bgMainPage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
      <Layout2>
        <SEO title="Home page Landing México" description={"Landing México - Exsis"}/> 
        <div style={backgroundStyle}>
          <DescriptionLMainPage />
          <BottomTextMainPage />
        </div>
      </Layout2>
  )
}
const WrappedComponent = () => (
  <LoadingProvider>
      <PostsContextProvider>
        <HomeLandingPageMx />
     </PostsContextProvider>
  </LoadingProvider>
);

export default withNamespaces()(WrappedComponent);
